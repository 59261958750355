import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/storage'
import './App.css'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import green from '@material-ui/core/colors/green'

var firebaseConfig = {
  apiKey: "AIzaSyDPhpDseOOheXA6ZEsjgX_BcuKks9uaSp8",
  authDomain: "asr-dispatch.firebaseapp.com",
  databaseURL: "https://asr-dispatch.firebaseio.com",
  projectId: "asr-dispatch",
  storageBucket: "asr-dispatch.appspot.com",
  messagingSenderId: "434470345795",
  appId: "1:434470345795:web:9d43cf96575a4c5a11c8f6"
}
firebase.initializeApp(firebaseConfig)
const functions = firebase.functions()
const storage = firebase.storage()

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: green["900"],
    color: 'white',
    '&:hover': {
        backgroundColor: green["700"],
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  notchedOutline: {
    '&:focus': {
      backgroundColor: green["700"],
    },
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0px'
  },
  link: {
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:visted': {
      color: 'black'
    },
    '&:hover': {
      color: 'black'
    }
  },
  terms: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function App() {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    message: ''
  })
  const [submissionState, setSubmissionState] = useState({status: 'initial'})
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  }
  const handleSubmit = async () => {
    setLoading(true)
    try {
      const captchaResponse = window.grecaptcha.getResponse()
      const { message } = values
      const orgId = window.location.pathname.replace('/', '');
      const response = await functions.httpsCallable('sendAnonymousReport')({message, orgId, captchaResponse, environment: window.location.origin})
      setSubmissionState(response.data)
    } catch (error) {
      setSubmissionState({status: 'error', error: error.message})
    }
    setLoading(false)
  }
  const handlePath = async () => {
    const { pathname } = window.location
    if (pathname.toLowerCase() === '/terms-of-service') {
      const url = await storage.ref('public/terms-of-service.pdf').getDownloadURL()
      window.location.href = url
    } else if (pathname.toLowerCase() === '/privacy-policy') {
      const url = await storage.ref('public/privacy-policy.pdf').getDownloadURL()
      window.location.href = url
    }
  }
  useEffect(() => {
    // not in iframe give bg
    if (window.location === window.parent.location) {
      document.getElementById('root').style.background = '#f5f5f5'
    }
    handlePath()
  }, [])
  return (
    <Container className={classes.container} maxWidth="sm">
      <Paper className={classes.root}>
        {submissionState.status !== 'success' && <form className={classes.form} noValidate autoComplete="off">
          <TextField
            id="message"
            label="Detailed Report"
            className={classes.textField}
            value={values.message}
            onChange={handleChange('message')}
            margin="normal"
            variant="outlined"
            multiline
            rows="4" 
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline
              }
            }} 
          />
          <Typography className={classes.badge}><Icon fontSize="small">lock</Icon>&nbsp;Anonymity ensured by&nbsp;<a className={classes.link} href="https://asralertsystems.com" target="_blank" rel="noopener noreferrer">ASR Alert Systems</a></Typography>
          {submissionState.status === 'error' && <Typography color="error">{submissionState.error || "Something Went Wrong! Please try again."}</Typography>}
          <div className="g-recaptcha" data-sitekey="6LfGM7sUAAAAAKwpx1lswTYq9R8bizt0vYfjnkM9" />
          <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit} disabled={loading}>
            Submit Anonymous Report
          </Button>
        </form>}
        {submissionState.status === 'success' && <Typography>Thank you for your report. Your anonymous message has been forwarded to the appropriate personel, and steps will be taken immediately.</Typography>}
        <div className={classes.terms}>
          <a className={classes.link} href="https://report.asralertsystems.com/terms-of-service" target="_blank" without="true" rel="noopener noreferrer">Terms Of Service</a>&nbsp; and &nbsp;<a className={classes.link} href="https://report.asralertsystems.com/privacy-policy" target="_blank" without="true" rel="noopener noreferrer">Privacy Policy</a>
        </div>
      </Paper>
    </Container>
  );
}

export default App;
